import { RefObject, useCallback, useState } from 'react'

import { useEventListener } from './useEventListener'
import { useIsomorphicEffect } from './useIsomorphicEffect'

interface Size {
  width: number
  height: number
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(elementRef: RefObject<T>): [Size, () => void] {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  const updateSize = useCallback(() => {
    const node = elementRef?.current

    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      })
    }
  }, [elementRef])

  // Initial size on mount
  useIsomorphicEffect(() => {
    updateSize()
  }, [])

  useEventListener('resize', updateSize)

  return [size, updateSize]
}
