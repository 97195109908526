import { pickBy } from 'lodash-es'

export const useMultipleSelectionAdapter = ({ defaultValue, value, onChange }: any) =>
  pickBy(
    {
      defaultSelectedItems: defaultValue,
      selectedItems: value,
      onSelectedItemsChange: ({ selectedItems }: any) => {
        if (onChange) {
          onChange(selectedItems)
        }
      },
    },
    (item) => item !== undefined,
  )
