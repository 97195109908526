import styled, { css, system, th } from '@xstyled/styled-components'

import { errorStyles, placeholderStyles, variant } from '@/shared/utils/styled'

export const BaseTextAreaField = styled.textareaBox`
  padding: 5px 10px !important;
  color: inherit;
  font: inherit;
  resize: none;
  appearance: none !important;
  border: none;
  outline: none;
  min-width: 100%;
  width: 100%;
  overflow: hidden;
  transition: border-color 0.14s ease-in-out;

  ::placeholder {
    ${placeholderStyles};
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }
`

const textFieldAreaVariants = {
  none: css`
    cursor: pointer;

    &:focus-within {
      cursor: text;
    }
  `,
  primary: css`
    border-color: ${th.color('grey.40')};
    background-color: ${th.color('white')};

    &:hover:not(.disabled) {
      border-color: ${th.color('grey.48')};
      background-color: ${th.color('grey.4')};
    }

    &:focus-within {
      border-color: ${th.color('purple.64')};
      background-color: ${th.color('white')};
    }
  `,
  tertiary: css`
    border-color: ${th.color('grey.100')};

    &:hover {
      background-color: ${th.color('grey.8')};
    }
  `,
  inline: css`
    &:focus-within {
      border-color: ${th.color('grey.32')};
    }
    &:hover:not(.disabled):not(:focus-within) {
      border-color: ${th.color('grey.12')};
    }
    &:disabled,
    &.disabled {
      border-color: transparent;
    }
  `,
  shadow: css`
    border-radius: 7px;
    cursor: pointer;
    color: ${th.color('grey.100')};
    background-color: ${th.color('white')};
    width: 100%;
    &:hover:not(.disabled):not(:focus) {
      border-color: ${th.color('grey.48')};
    }
    &:focus {
      box-shadow: ${th.shadow('light')};
    }
  `,
}

export const textFieldAreaVariant = variant({
  default: 'primary',
  variants: textFieldAreaVariants,
})
export type TextFieldVariantProps = { readonly variant?: keyof typeof textFieldAreaVariants }

export const TextAreaField = styled.textareaBox`
  padding: 5px 10px;
  font: inherit;
  resize: none;
  appearance: none;
  outline: none;
  overflow: hidden;
  line-height: normal;
  transition: border-color 0.03s ease-in-out;
  background-color: ${th.color('white')};

  ::placeholder {
    ${placeholderStyles};
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }

  display: block;
  width: 100%;
  min-width: 100%;
  color: ${th.color('grey.100')};
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;

  &.disabled,
  &:disabled {
    border-color: transparent;
    background-color: transparent;
  }

  ${textFieldAreaVariant}
  ${errorStyles as any}

  && {
    ${system}
  }
`

export const ErrorText = styled.pBox`
  color: ${'red.error'};
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
`

export type TextAreaFieldProps = SCProps<typeof TextAreaField>
