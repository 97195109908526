import { FastField, Field, FieldProps } from 'formik'
import React from 'react'

import { TextField, TextFieldProps } from '@/kit'

import { FormikErrorMessage } from './FormikErrorMessage'

export type FormikTextFieldProps = { name: string; isFast?: boolean } & TextFieldProps

export const FormikTextField = React.forwardRef<HTMLInputElement, FormikTextFieldProps>(
  ({ name, isFast, children, onBlur, ...rest }, ref) => {
    const FormikField = isFast ? FastField : Field

    return (
      <FormikField name={name}>
        {(formik: FieldProps) => {
          const { field, form, meta } = formik || {}
          const { setFieldValue, setFieldTouched } = form || {}
          const { touched, error } = meta || {}

          const formikError = Boolean(touched && error)

          return (
            <TextField
              {...field}
              onChange={(v) => setFieldValue(name, v)}
              onBlur={() => {
                onBlur?.(field?.value)
                setFieldTouched(name, true)
              }}
              error={formikError}
              {...rest}
              ref={ref}
            >
              <FormikErrorMessage name={name} />
              {children}
            </TextField>
          )
        }}
      </FormikField>
    )
  },
)
