import styled, { th, space, SpaceProps, width, WidthProps } from '@xstyled/styled-components'

import { BaseTextField, TextField } from '../TextField/TextField.styled'

export const Container = styled.div<SpaceProps & WidthProps>`
  position: relative;
  ${space}
  ${width}
`

export const Control = styled(TextField).attrs(() => ({ as: 'div' }))`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  color: ${th.color('grey.100')};
  border-color: ${th.color('grey.40')};

  & > * ~ * {
    margin-left: 10px;
  }

  ${BaseTextField} {
    flex: 1;
    height: 100%;
    background-color: transparent;
    border: none;
  }
`
