import styled, {
  HeightProps,
  SpaceProps,
  WidthProps,
  compose,
  css,
  height,
  space,
  width,
} from '@xstyled/styled-components'
import classNames from 'classnames'

import {
  ErrorStylesProps,
  SizeVariantProps,
  errorStyles,
  placeholderStyles,
  sizeVariant,
  variant,
} from '@/shared/utils/styled'

export const BaseTextField = styled.inputBox`
  color: inherit;
  font: inherit;
  resize: none;
  appearance: none !important;
  outline: none;
  transition: background-color 0.14s ease-in-out, box-shadow 0.14s ease-in-out, outline 0.14s ease-in-out,
    color 0.14s ease-in-out;

  ::placeholder {
    ${placeholderStyles};
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }
`

const textFieldVariants = {
  none: css`
    cursor: pointer;

    &:focus-within {
      cursor: text;
    }
  `,
  primary: css`
    border-color: ${'grey.24'};
    background-color: ${'white'};

    transition: border-color 0.14s ease-in-out;

    &:hover:not(.disabled):not(:focus-within) {
      border-color: ${'grey.48'};
    }

    &:focus-within {
      border-color: ${'purple.64'};
      background-color: ${'white'};
    }
  `,
  tertiary: css``,
  inline: css`
    background-color: transparent;

    &:focus-within {
      border-color: ${'grey.100'};
    }
    &:hover:not(.disabled) {
      border-color: ${'grey.40'};
    }
    &:disabled,
    &.disabled {
      border-color: transparent;
    }
  `,
  get shadow() {
    return css`
      border-radius: 7px;
      cursor: pointer;
      color: ${'grey.100'};
      &:hover:not(.disabled):not(:focus) {
        border-color: ${'grey.48'};
      }
      &:focus {
        box-shadow: ${'light'};
      }
    `
  },
}

export const textFieldVariant = variant({
  default: 'primary',
  variants: textFieldVariants,
})
export type TextFieldVariantProps = { readonly variant?: keyof typeof textFieldVariants }

export type TextFieldSystemProps = TextFieldVariantProps &
  SizeVariantProps &
  ErrorStylesProps &
  SpaceProps &
  WidthProps &
  HeightProps

const textFieldSystemProps = compose(space, width, height)

export const TextField = styled(BaseTextField).attrs<any>((props) => ({
  className: classNames({ disabled: props.disabled }),
}))<TextFieldSystemProps>`
  display: block;
  width: 100%;
  color: ${'grey.100'};
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;

  &.disabled,
  &:disabled {
    border-color: ${'grey.8'};
    background-color: ${'grey.4'};
  }

  ${textFieldVariant}
  ${sizeVariant}
  ${errorStyles}
  ${textFieldSystemProps}
`

export const ErrorText = styled.pBox`
  color: ${'red.error'};
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`

export type TextFieldProps = SCProps<typeof TextField>
