import { x } from '@xstyled/styled-components'
import React from 'react'
import _ from 'lodash'
import { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu'

import { useElementSize, useOnWindowResize } from '@/shared/hooks'
import { Icons } from '@/assets'

import { Separator } from '../Separator'
import * as S from './Dropdown.styled'
import { Label } from '../Label'
import { DropdownMenu } from '../DropdownMenu'

export interface DropdownMenuItem {
  labelText?: string | number | null
  label: string | JSX.Element
  labelDisplay?: string | JSX.Element
  value: string | number | any
  disabled?: boolean
  style?: React.CSSProperties
}

export interface DropdownProps {
  placeholder: string | JSX.Element
  menuHeader?: string | JSX.Element
  items?: DropdownMenuItem[]
  onSelect: (item: DropdownMenuItem) => void
  onBlur?: () => void
  renderTrigger?: (selectedItem?: DropdownMenuItem) => JSX.Element
  renderMenu?: (children: Children, menuHeader: DropdownProps['menuHeader']) => JSX.Element
  renderMenuHeader?: (selectedItem?: DropdownMenuItem) => JSX.Element
  renderMenuItem?: (
    item: DropdownMenuItem,
    itemKey: string,
    onSelect: DropdownProps['onSelect'],
    isSelected: boolean,
  ) => JSX.Element
  value?: DropdownMenuItem['value']
  defaultValue?: DropdownMenuItem['value']
  error?: boolean
  disabled?: boolean
  label?: string | JSX.Element
  valueOverride?: string | JSX.Element
  withChevron?: boolean
  matchMenuWidthToTriggerWidth?: boolean
  triggerStyle?: React.CSSProperties
  dropdownItemStyle?: React.CSSProperties
  dropdownMenuContentProps?: DropdownMenuContentProps
  xss?: SCProps<typeof x.div>
}

export const Dropdown = ({
  placeholder,
  menuHeader,
  items,
  value,
  defaultValue,
  label,
  valueOverride,
  onSelect,
  onBlur,
  error,
  disabled,
  renderTrigger,
  renderMenuHeader,
  renderMenuItem,
  withChevron = true,
  triggerStyle,
  dropdownItemStyle,
  dropdownMenuContentProps,
  matchMenuWidthToTriggerWidth,
  xss,
}: DropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false)

  const triggerRef = React.useRef(null)
  const [triggerSize, updateTriggerSize] = useElementSize(triggerRef)
  const [selectedItem, setSelectedItem] = React.useState<DropdownMenuItem | undefined>(
    items?.find((i) => i.value === value) || items?.find((i) => i.value === defaultValue),
  )

  React.useEffect(() => {
    if (value == null) {
      if (defaultValue == null) return setSelectedItem(undefined)

      return setSelectedItem(items?.find((i) => i.value === defaultValue))
    }

    setSelectedItem(items?.find((i) => _.isEqual(i.value, value)))
  }, [value, defaultValue, items])

  const isSelected = React.useCallback((item) => item.value === selectedItem?.value, [selectedItem])

  const handleSelect = React.useCallback(
    (item) => {
      setSelectedItem(item)
      onSelect?.(item)
      setIsDropdownOpen(false)
    },
    [onSelect],
  )

  const memoItems = React.useMemo(() => items, [items])

  useOnWindowResize(updateTriggerSize, matchMenuWidthToTriggerWidth, matchMenuWidthToTriggerWidth)

  return (
    <DropdownMenu.Root
      open={isDropdownOpen}
      onOpenChange={(openState) => {
        setIsDropdownOpen(openState)
        if (openState === false) onBlur?.()
      }}
    >
      <x.div display="flex" flexDirection="column" w="100%" {...xss}>
        {label && <Label>{label}</Label>}
        <DropdownMenu.Trigger
          ref={triggerRef}
          style={{
            padding: 0,
            borderRadius: 5,
            outline: 'none',
            width: '100%',
            background: 'transparent',
            cursor: disabled ? 'default' : 'pointer',
            ...triggerStyle,
          }}
          tabIndex={-1}
          disabled={disabled}
        >
          <>
            {renderTrigger && renderTrigger(selectedItem)}
            {!renderTrigger && (
              <S.DropdownHeader tabIndex={0} data-error={error} data-disabled={disabled}>
                {selectedItem && (
                  <S.DropdownHeaderValue>{selectedItem?.labelDisplay || selectedItem?.label}</S.DropdownHeaderValue>
                )}
                {!selectedItem && !valueOverride && (
                  <S.DropdownHeaderPlaceholder>{placeholder}</S.DropdownHeaderPlaceholder>
                )}
                {!selectedItem && valueOverride && (
                  <S.DropdownHeaderPlaceholder>{valueOverride}</S.DropdownHeaderPlaceholder>
                )}
                {withChevron && <Icons.ChevronIcon className="DropdownChevron" height={20} width={20} />}
              </S.DropdownHeader>
            )}
          </>
        </DropdownMenu.Trigger>
      </x.div>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          asChild
          avoidCollisions={true}
          collisionPadding={{ right: 20, left: 20, top: 20, bottom: 20 }}
          w={matchMenuWidthToTriggerWidth ? triggerSize?.width : undefined}
          {...dropdownMenuContentProps}
        >
          <div>
            {renderMenuHeader && renderMenuHeader(selectedItem)}
            {!renderMenuHeader && menuHeader && <S.DropdownMenuHeader>{menuHeader}</S.DropdownMenuHeader>}
            {renderMenuHeader && <Separator />}
            <S.DropdownMenuItems>
              {memoItems?.map((item, idx) => {
                if (renderMenuItem) return renderMenuItem(item, `${item.value}_${idx}`, handleSelect, isSelected(item))

                return (
                  <S.DropdownMenuItem
                    key={`${item.value}_${idx}`}
                    onSelect={() => handleSelect(item)}
                    data-selected={isSelected(item)}
                    data-disabled={item?.disabled}
                    style={{ ...dropdownItemStyle, ...item?.style }}
                    disabled={item?.disabled}
                  >
                    <x.span maxW="85%">{item.label}</x.span>
                    {isSelected(item) && <Icons.CheckIcon height={14} width={18} className="CheckMarkIcon" />}
                  </S.DropdownMenuItem>
                )
              })}
            </S.DropdownMenuItems>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
