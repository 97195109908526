import * as RadixLabel from '@radix-ui/react-label'
import styled, { SystemProps, system, th } from '@xstyled/styled-components'

export const Label = styled(RadixLabel.Root)<SystemProps>`
  display: block;
  margin-bottom: 10px;
  color: ${th.color('grey.100')};
  font-size: ${th.fontSize('sm')};
  font-weight: semibold;
  line-height: 18px;

  ${system}
`
