import * as RadixCheckbox from '@radix-ui/react-checkbox'
import styled, { SystemProps, system, th } from '@xstyled/styled-components'

import { errorStyles } from '@/shared/utils/styled'

export const $Label = styled.label<SystemProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${system}
`

export const $Checkbox = styled(RadixCheckbox.Root)<SystemProps & { error?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px;
  width: 18px;
  height: 18px;
  appearance: none;
  background-color: transparent;
  border: 1px solid ${th.color('grey.16')};
  border-radius: 3px;
  outline: none;
  transition: background-color 0.14s ease-in-out, border-color 0.14s ease-in-out;

  :focus {
    outline: none;
  }

  :focus-visible {
    outline: 2px solid ${th.color('purple.48')};
    outline-offset: 2px;
  }

  :hover:not(:disabled):not([data-state='checked']):not([data-state='indeterminate']) {
    background-color: ${th.color('grey.4')};
    border-color: ${th.color('grey.48')};
  }

  &[data-state='checked'],
  &[data-state='indeterminate'] {
    border-color: ${th.color('purple.48')};
    background-color: ${th.color('purple.48')};

    &:disabled {
      background-color: ${th.color('white')};
      color: ${th.color('grey.16')};

      path {
        fill: ${th.color('grey.16')};
      }
    }

    svg {
      padding: 1px;
    }
  }

  :disabled {
    cursor: not-allowed;
    border-color: ${th.color('grey.16')};
    color: ${th.color('grey.4')};
    background-color: ${th.color('grey.4')};
  }

  ${errorStyles}
  ${system}
`

export const $Indicator = styled(RadixCheckbox.Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: currentColor;
`
