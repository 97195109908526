import React from 'react'

export const useIsFocusedRef = (): [React.RefObject<HTMLInputElement>, boolean] => {
  const ref = React.useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = React.useState(false)

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  React.useEffect(() => {
    const node = ref.current

    if (!node) return

    node.addEventListener('focus', handleFocus)
    node.addEventListener('blur', handleBlur)

    return () => {
      node.removeEventListener('focus', handleFocus)
      node.removeEventListener('blur', handleBlur)
    }
  }, [])

  return [ref, isFocused]
}
