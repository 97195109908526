import { ErrorMessage } from 'formik'
import React from 'react'

import { FieldErrorMessage } from '@/kit'

export type FormikErrorMessageProps = React.ComponentProps<typeof FieldErrorMessage> &
  React.ComponentProps<typeof FieldErrorMessage>

export const FormikErrorMessage = ({ name, ...styledProps }: FormikErrorMessageProps) => {
  const ErrorMessageTemp = ErrorMessage as any

  return (
    <ErrorMessageTemp name={name}>
      {(errorMessage: React.ReactNode) => <FieldErrorMessage {...styledProps}>{errorMessage}</FieldErrorMessage>}
    </ErrorMessageTemp>
  )
}
