import { FastField, Field, FieldProps } from 'formik'
import React from 'react'
import styled, { x } from '@xstyled/styled-components'

import { Label } from '@/kit'
import { useIsFocusedRef } from '@/shared/hooks'

import { FormikErrorMessage } from './FormikErrorMessage'

export const $InputField = styled.inputBox`
  padding: 0px;
  color: ${'grey.100'};
  background-color: transparent;
  line-height: 18px;
  width: 100%;

  ::placeholder {
    color: ${'grey.48'};
  }
`

export interface FormikFormattedTextFieldProps {
  name: string
  label?: React.ReactNode
  placeholder?: string
  isFast?: boolean
  onChange?: (value: string) => void
  maxLength?: number
  beforeText: string
  containerStyle?: SCProps<typeof x.div>
  beforeTextStyle?: SCProps<typeof x.span>
  inputStyle?: Omit<SCProps<typeof x.input>, 'value' | 'onChange' | 'onBlur'>
}

export const FormikFormattedTextField = ({
  name,
  label,
  placeholder,
  isFast,
  onChange,
  beforeText,
  containerStyle,
  beforeTextStyle,
  inputStyle,
  maxLength,
}: FormikFormattedTextFieldProps) => {
  const FormikField = isFast ? FastField : Field
  const [inputRef, isInputFocused] = useIsFocusedRef()

  return (
    <FormikField name={name}>
      {(formik: FieldProps) => {
        const { field, form, meta } = formik || {}
        const { setFieldValue } = form || {}
        const { touched, error } = meta || {}

        const formikError = Boolean(touched && error)

        return (
          <x.div display="flex" flexDirection="column" {...containerStyle}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <x.div
              display="flex"
              flexDirection="row"
              alignItems="center"
              border="1px solid"
              borderColor={formikError ? 'red.error' : isInputFocused ? 'purple.48' : 'grey.12'}
              borderRadius="5px"
              minHeight="34px"
              p="0px 10px"
            >
              <x.span fontSize="14px" color="grey.48" onClick={() => inputRef?.current?.focus()} {...beforeTextStyle}>
                {beforeText}
              </x.span>
              <$InputField
                p="0"
                ref={inputRef as any}
                name={name}
                value={field?.value || ''}
                placeholder={placeholder}
                maxLength={maxLength}
                onChange={(e: any) => {
                  const newValue = e?.target?.value
                  setFieldValue(name, newValue)
                  onChange?.(newValue)
                }}
                onBlur={field?.onBlur}
                outline="none"
                {...inputStyle}
              />
            </x.div>
            {formikError && <FormikErrorMessage name={name} />}
          </x.div>
        )
      }}
    </FormikField>
  )
}
