import { FastField, Field, FieldProps } from 'formik'
import React from 'react'
import styled, { th, x } from '@xstyled/styled-components'

import { Dropdown, DropdownProps, Label } from '@/kit'
import {
  DropdownHeader,
  DropdownMenuContent,
  DropdownMenuHeader,
  DropdownMenuItem,
} from '@/kit/Dropdown/Dropdown.styled'
import { Icons } from '@/assets'

import { FormikErrorMessage } from './FormikErrorMessage'

export type FormikSelectProps = Omit<DropdownProps, 'onSelect' | 'placeholder'> & {
  name: string
  label: string
  placeholder?: string
  isFast?: boolean
  onChange?: (value: any) => void
  xss?: SCProps<typeof x.div>
}

const FormikSelectTrigger = styled(DropdownHeader)`
  color: ${th.color('grey.100')};
  outline: none;

  :focus-visible {
    border: 1px solid ${th.color('purple.64')};
  }

  &[data-error='true'] {
    border: 1px solid ${th.color('red.error')};
  }

  .DropdownChevron {
    path {
      fill: ${th.color('grey.48')};
    }
  }
`

const FormikSelectChevronIcon = styled(Icons.ChevronIcon)`
  position: absolute;
  right: 11px;
  top: 8px;
  transform: rotate(180deg);

  path {
    fill: ${th.color('grey.100')};
  }
`

const FormikSelectMenuHeader = styled(DropdownMenuHeader)`
  display: flex;
  min-height: 34px;
  align-items: center;
`

const FormikSelectMenuItem = styled(DropdownMenuItem)`
  padding: 0 10px;
  min-height: 34px;

  font-size: 14px;
`

export const FormikSelectMenu = styled(DropdownMenuContent)`
  min-width: 301px;
`

export function FormikSelect({ isFast, name, label, value, onChange, xss, placeholder, ...rest }: FormikSelectProps) {
  const FieldComponent = isFast ? FastField : Field

  return (
    <FieldComponent name={name}>
      {(formik: FieldProps) => {
        const { field, form, meta } = formik || {}
        const { setFieldValue, setFieldTouched } = form || {}
        const { touched, error } = meta || {}

        const formikError = Boolean(touched && error && typeof error === 'string')

        return (
          <x.div display="flex" flexDirection="column" {...xss}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <Dropdown
              {...rest}
              placeholder={placeholder || ''}
              value={value ?? field.value}
              matchMenuWidthToTriggerWidth
              onSelect={async (selectedItem) => {
                setFieldValue(name, selectedItem?.value, true)
                if (onChange) onChange(selectedItem?.value)
                if (!touched) setFieldTouched(name, true, false)
              }}
              onBlur={() => {
                setFieldTouched(name, true)
              }}
              renderTrigger={(selectedItem) => (
                <FormikSelectTrigger data-error={formikError} tabIndex={0}>
                  <x.div>{selectedItem?.labelDisplay || selectedItem?.label || placeholder}</x.div>
                  <Icons.ChevronIcon className="DropdownChevron" height={20} width={20} />
                </FormikSelectTrigger>
              )}
              renderMenu={(children) => <FormikSelectMenu>{children}</FormikSelectMenu>}
              renderMenuHeader={() => (
                <FormikSelectMenuHeader>
                  <FormikSelectChevronIcon height={22} />
                </FormikSelectMenuHeader>
              )}
              renderMenuItem={(item, itemKey, handleSelect, isSelected) => (
                <FormikSelectMenuItem key={itemKey} data-selected={isSelected} onClick={() => handleSelect(item)}>
                  {item.label}
                </FormikSelectMenuItem>
              )}
              dropdownMenuContentProps={{
                style: { minWidth: '301px' },
                sideOffset: -35,
              }}
              error={formikError}
            />
            {formikError && <FormikErrorMessage name={name} />}
          </x.div>
        )
      }}
    </FieldComponent>
  )
}
