import { FastField, Field, FieldProps } from 'formik'
import React from 'react'

import { TextAreaField, TextAreaFieldProps } from '@/kit'

import { FormikErrorMessage } from './FormikErrorMessage'

export type FormikTextAreaFieldProps = { name: string; isFast?: boolean } & TextAreaFieldProps

export const FormikTextAreaField = ({ name, isFast, children, onBlur, ...rest }: FormikTextAreaFieldProps) => {
  const FormikField = isFast ? FastField : Field

  return (
    <FormikField name={name}>
      {(formik: FieldProps) => {
        const { field, form, meta } = formik || {}
        const { setFieldValue } = form || {}
        const { touched, error } = meta || {}

        const formikError = Boolean(touched && error)

        return (
          <TextAreaField
            {...field}
            onChange={(v) => setFieldValue(name, v)}
            onBlur={() => onBlur?.(field?.value)}
            error={formikError}
            {...(rest as any)}
          >
            <FormikErrorMessage name={name} />
            {children}
          </TextAreaField>
        )
      }}
    </FormikField>
  )
}
