import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, { th } from '@xstyled/styled-components'

import { scaleFadeIn, scaleFadeOut } from '@/theme'

export const DropdownHeaderValue = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 20px);
  color: ${th.color('grey.100')};
  font-size: 12px;
  padding: 1px 0;
`

export const DropdownHeaderPlaceholder = styled(DropdownHeaderValue)`
  color: ${th.color('grey.48')};
  font-size: 12px;
`

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 0 10px;
  background-color: ${'white'};
  border: 1px solid;
  border-color: ${'grey.16'};
  color: ${'grey.48'};
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 100%;
  width: 100%;
  position: relative;
  outline: none;

  transition: border-color 0.14s ease-in-out, background-color 0.14s ease-in-out;

  &:hover:not([data-disabled='true']) {
    border-color: ${'grey.48'};
    background-color: ${'white'};
  }

  &:focus-visible:not([data-disabled='true']) {
    color: ${'grey.64'};
    border-color: ${'purple.64'};
    background-color: ${'white'};

    .DropdownChevron path {
      fill: ${'grey.64'};
    }

    ${DropdownHeaderPlaceholder} {
      color: ${'grey.64'};
    }
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
    background-color: ${'grey.4'};
  }

  &[data-error='true'] {
    border-color: ${'red.error'};
  }

  .DropdownChevron {
    margin-top: 2px;
  }
`

export const DropdownMenuContent = styled(DropdownMenu.Content)`
  background-color: ${th.color('white')};
  width: calc(100% + 3px);
  min-width: 202px;
  max-height: 300px;
  margin-left: -1px;
  border-radius: 5px;
  border: 1px solid ${th.color('grey.12')};
  box-shadow: ${th.shadow('normal')};
  overflow-x: hidden;
  pointer-events: auto;

  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  transition: transform 0.2s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.2s ease-in-out;
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);

  &[data-state='open'] {
    animation-name: ${scaleFadeIn};
  }

  &[data-state='closed'] {
    animation-name: ${scaleFadeOut};
  }
`

export const DropdownMenuHeader = styled.div`
  color: ${th.color('grey.100')};
  font-size: 12px;
  padding: 5px 10px;
`

export const DropdownMenuItems = styled.divBox`
  max-height: 250px;
  overflow-y: auto;
`

export const DropdownMenuItem = styled(DropdownMenu.Item)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: ${th.color('white')};
  color: ${th.color('grey.100')};
  font-size: 12px;
  cursor: pointer;
  overflow-x: hidden;

  outline: none;

  :hover,
  :focus {
    background-color: ${th.color('grey.4')};
  }

  &[data-selected='true'] {
    background-color: ${th.color('purple.4')};

    .CheckMarkIcon svg {
      height: 14px;
      width: 18px;
    }

    .CheckMarkIcon path {
      fill: ${th.color('purple.100')};
    }
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
    background-color: ${th.color('grey.4')};
    opacity: 0.5;
  }
`
