import { isClient } from '../utils'
import { useGlobalObjectEventListener } from './useGlobalObjectEventListener'

/**
 *
 * useOnWindowResize hook
 *
 * Fires a callback when window resizes
 *
 * @param {Function} callback Callback to be called before unmount
 * @param {boolean} when When the handler should be applied
 * @param {boolean} isLayoutEffect Should it use layout effect. Defaults to false
 */
function useOnWindowResize(callback: (event: any) => void, when = true, isLayoutEffect = false) {
  useGlobalObjectEventListener(
    isClient ? window : undefined,
    'resize',
    callback,
    { passive: true },
    when,
    isLayoutEffect,
  )
}

export { useOnWindowResize }
