import React from 'react'
import { Field, FieldProps } from 'formik'

import { RadioGroup } from '@/kit'

import { FormikErrorMessage } from './FormikErrorMessage'

type FormikRadioGroupRootProps = { name: string; isFast?: boolean } & Omit<
  React.ComponentProps<typeof RadioGroup.Root>,
  'onValueChange'
>

export const FormikRadioGroupRoot = ({ name, children, ...rest }: FormikRadioGroupRootProps) => (
  <Field name={name}>
    {(formik: FieldProps) => {
      const { field, form, meta } = formik || {}
      const { value } = field || {}
      const { setFieldValue } = form || {}
      const { touched, error } = meta || {}

      const formikError = Boolean(touched && error)

      return (
        <RadioGroup.Root
          value={value}
          onValueChange={(event) => {
            setFieldValue(name, (event as any)?.target?.value)
          }}
          error={formikError}
          {...rest}
        >
          {children}
          <FormikErrorMessage name={name} />
        </RadioGroup.Root>
      )
    }}
  </Field>
)

export const FormikRadioGroup = {
  Root: FormikRadioGroupRoot,
  ItemPrimary: RadioGroup.ItemPrimary,
  ItemSecondary: RadioGroup.ItemSecondary,
}
