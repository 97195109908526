import React from 'react'
import { Field, FastField, FieldProps } from 'formik'

import { TextFieldWithOptions, TextFieldWithOptionsProps } from '@/kit'

import { FormikErrorMessage } from './FormikErrorMessage'

export type FormikTextFieldWithOptionsProps = { name: string; isFast?: boolean } & TextFieldWithOptionsProps<any>

export const FormikTextFieldWithOptions = ({ name, isFast, children, ...rest }: FormikTextFieldWithOptionsProps) => {
  const FormikField = isFast ? FastField : Field

  return (
    <FormikField name={name}>
      {(formik: FieldProps) => {
        const { form, meta } = formik || {}
        const { setFieldValue } = form || {}
        const { touched, error } = meta || {}

        const formikError = Boolean(touched && error)

        return (
          <TextFieldWithOptions onChange={(v) => setFieldValue(name, v)} error={formikError} {...rest}>
            <FormikErrorMessage name={name} />
            {children}
          </TextFieldWithOptions>
        )
      }}
    </FormikField>
  )
}
