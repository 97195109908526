import { Icons } from '@/assets'

import * as S from './RadioGroup.styled'

const RadioItemPrimary: React.FC<SCProps<typeof S.RadioItemPrimary>> = ({ children, value, ...props }) => (
  <S.RadioLabelPrimary className="RadioLabelPrimary" htmlFor={value}>
    <S.RadioItemPrimary id={value} value={value} {...props}>
      <S.IndicatorPrimary {...props} />
    </S.RadioItemPrimary>
    {children}
  </S.RadioLabelPrimary>
)

const RadioItemSecondary: React.FC<SCProps<typeof S.RadioItemSecondary>> = ({ children, value, ...props }) => (
  <S.RadioItemSecondary id={value} value={value} {...props}>
    {children}
    <S.IndicatorSecondary forceMount={true}>
      <Icons.CheckIcon height={14} width={18} />
    </S.IndicatorSecondary>
  </S.RadioItemSecondary>
)

export const RadioGroup = {
  Root: S.RadioRoot,
  ItemPrimary: RadioItemPrimary,
  ItemSecondary: RadioItemSecondary,
}
