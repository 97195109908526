import React from 'react'
import { Field, FastField, FieldProps } from 'formik'

import { Checkbox, CheckboxProps } from '@/kit'

export type FormikCheckboxProps = { name: string; isFast?: boolean } & CheckboxProps

export const FormikCheckbox = ({ name, isFast, onCheckedChange, ...rest }: FormikCheckboxProps) => {
  const FormikField = isFast ? FastField : Field

  return (
    <FormikField name={name}>
      {(formik: FieldProps) => {
        const { field, form, meta } = formik || {}
        const { setFieldValue } = form || {}
        const { touched, error } = meta || {}

        const formikError = Boolean(touched && error)

        return (
          <Checkbox
            {...field}
            onCheckedChange={(checkedState) => {
              setFieldValue(name, checkedState)
              onCheckedChange?.(checkedState)
            }}
            error={formikError || undefined}
            {...rest}
          />
        )
      }}
    </FormikField>
  )
}
