import { useFreshRef } from './useFreshRef'

export function useFreshTick(callback: (...args: any[]) => void) {
  const freshRef = useFreshRef(callback)
  function tick(...args: any[]) {
    if (freshRef && typeof freshRef.current === 'function') {
      freshRef.current(...args)
    }
  }

  return tick
}
