import * as RadixLabel from '@radix-ui/react-label'
import * as RadixRadioGroup from '@radix-ui/react-radio-group'
import styled, {
  BackgroundColorProps,
  FlexboxesProps,
  SpaceProps,
  compose,
  css,
  display,
  flexboxes,
  sizing,
  space,
  th,
} from '@xstyled/styled-components'

import { ErrorStylesProps, SizeVariantProps, errorStyles, sizeVariant } from '@/shared/utils/styled'

export const RadioItemPrimary = styled(RadixRadioGroup.Item)`
  appearance: unset !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  appearance: none;
  background-color: transparent;
  border: 1px solid ${th.color('grey.16')};
  border-radius: 50%;
  transition: border-color 0.14s ease-in-out, background-color 0.14s ease-in-out, outline 0.14s ease-in-out;

  &:hover {
    border-color: ${th.color('grey.48')};
    background-color: ${th.color('grey.4')};
  }

  &:focus-visible {
    border-color: ${th.color('purple.64')};
    background-color: ${th.color('purple.4')};
    outline: none;
  }

  &[data-state='checked'] {
    border-color: ${th.color('purple.64')};
  }
`

export const RadioLabelPrimary = styled(RadixLabel.Root)`
  display: flex;
  color: ${th.color('grey.100')};
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;

  ${RadioItemPrimary} {
    margin-right: 15px;
  }

  button {
    outline: none;
  }
`

export const IndicatorPrimary = styled(RadixRadioGroup.Indicator)`
  width: 14px;
  height: 14px;
  background-color: ${th.color('purple.64')};
  border-radius: 50%;

  button {
    outline: none;
  }
`

type RadioItemSecondaryProps = SizeVariantProps & ErrorStylesProps & BackgroundColorProps
export const RadioItemSecondary = styled(RadixRadioGroup.Item)<RadioItemSecondaryProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${th.color('grey.48')};
  border-color: ${th.color('grey.24')};
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  appearance: none !important;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  transition: border-color 0.14s ease-in-out, background-color 0.14s ease-in-out, outline 0.14s ease-in-out;

  &:disabled {
    border-color: ${th.color('grey.8')};
  }

  &:hover {
    background-color: ${th.color('grey.4')};
    border-color: ${th.color('grey.48')};
    color: ${th.color('grey.64')};
  }

  &[data-state='checked'] {
    background-color: ${th.color('purple.4')};
    border-color: ${th.color('purple.64')};
    color: ${th.color('purple.64')};
  }

  :focus {
    outline: none;
  }

  :focus-visible {
    border-color: ${th.color('grey.48')};
    background-color: ${th.color('grey.4')};
    outline: 2px solid ${th.color('purple.64')};
    outline-offset: 5px;
    outline-style: auto;
  }

  ${sizeVariant}
  ${errorStyles}
`

export const IndicatorSecondary = styled(RadixRadioGroup.Indicator)`
  margin-left: 15px;
  color: ${th.color('purple.64')};
  visibility: hidden;

  button {
    outline: none;
  }

  &[data-state='checked'] {
    visibility: visible;

    path {
      fill: ${th.color('purple.64')};
    }
  }
`
const radioRootSystemProps = compose(space, display, flexboxes, sizing)
export const RadioRoot = styled(RadixRadioGroup.Root).withConfig({
  shouldForwardProp: (prop, validate) => validate(prop) && !radioRootSystemProps.meta.props.includes(prop),
})<SpaceProps & FlexboxesProps & { error?: boolean }>`
  display: flex;
  ${(props) =>
    props.error &&
    css`
      ${RadioItemPrimary}, ${RadioItemSecondary} {
        border-color: ${th.color('red.error')};
      }
      ${IndicatorPrimary} {
        background-color: ${th.color('red.error')};
      }
    `}
  ${radioRootSystemProps}
`
