import { UseMultipleSelectionProps, useMultipleSelection } from 'downshift'
import React from 'react'

import { Label } from '../Label'
import { BaseTextField, TextFieldProps } from '../TextField/TextField.styled'
import { SelectedItem } from './SelectedItem'
import * as S from './TextFieldWithOptions.styled'
import { useMultipleSelectionAdapter } from './utils'

export type TextFieldWithOptionsProps<T> = UseMultipleSelectionProps<T> &
  TextFieldProps & {
    className?: string
    xss?: SCProps<typeof S.Container>
    label?: string
    value?: T[] | null
    defaultValue?: T[] | null
    children?: React.ReactNode
    onChange?: (selectedItems: T[]) => void
    onBlur?: () => void
  }

export const TextFieldWithOptions = React.forwardRef<HTMLDivElement, TextFieldWithOptionsProps<any>>(
  (
    {
      className,
      xss,
      children,
      label,
      defaultValue = [],
      value,
      onChange,
      placeholder,
      disabled,
      variant,
      s,
      error,
      ...inputProps
    },
    outerRef,
  ) => {
    const [inputValue, setInputValue] = React.useState('')

    const { getDropdownProps, getSelectedItemProps, addSelectedItem, removeSelectedItem, selectedItems } =
      useMultipleSelection({
        ...useMultipleSelectionAdapter({ defaultValue, value, onChange }),
      })

    const handleRemoveItem = React.useCallback(
      (selectedItem) => {
        removeSelectedItem(selectedItem)
      },
      [removeSelectedItem],
    )

    const handleCreateItem = React.useCallback(() => {
      if (inputValue) {
        addSelectedItem(inputValue)
        setInputValue('')
      }
    }, [addSelectedItem, inputValue])

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleCreateItem()
          if (event.key === ' ') {
            event.preventDefault()
          }
        }
      },
      [handleCreateItem],
    )

    const handleBlur = React.useCallback(() => {
      handleCreateItem()
    }, [handleCreateItem])

    return (
      <S.Container ref={outerRef} className={className} {...xss}>
        {label && <Label>{label}</Label>}
        <S.Control {...{ disabled, error, variant, s }}>
          {selectedItems?.map((selectedItem, index) => (
            <SelectedItem
              key={index}
              {...getSelectedItemProps({ selectedItem, index })}
              onRemove={() => handleRemoveItem(selectedItem)}
            >
              {selectedItem}
            </SelectedItem>
          ))}
          <BaseTextField
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            {...getDropdownProps({
              placeholder: selectedItems.length ? undefined : placeholder,
              disabled,
              onKeyDown: handleKeyDown,
              onBlur: handleBlur,
              ...inputProps,
            })}
          />
        </S.Control>
        {children}
      </S.Container>
    )
  },
)

TextFieldWithOptions.displayName = 'TextFieldWithOptions'
