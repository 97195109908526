import React from 'react'
import { x } from '@xstyled/styled-components'

import { Label } from '../Label'
import * as S from './TextAreaField.styled'

export type TextAreaFieldProps = Omit<S.TextAreaFieldProps, 'onChange' | 'onBlur'> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> & {
    xss?: SCProps<typeof x.div>
    textAreaFieldStyle?: SCProps<typeof S.TextAreaField>
    onChange?: (value: string) => void
    onBlur?: (value: string) => void
    transform?: (value: string) => string
    label?: React.ReactNode
    maxLength?: any
    minWidth?: any
    error?: boolean | string
    variant?: 'primary' | 'tertiary' | 'shadow' | 'inline' | 'none'
    isResizable?: boolean
  }

export const TextAreaField = React.forwardRef<Omit<HTMLInputElement, 'onChange'>, TextAreaFieldProps>(
  (
    {
      xss,
      children,
      name,
      label,
      onChange,
      onBlur,
      transform = (v) => v,
      minWidth,
      isResizable = true,
      textAreaFieldStyle,
      error,
      ...rest
    },
    ref,
  ) => {
    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(transform(event.target.value))
        }
      },
      [onChange, transform],
    )

    const handleBlur = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(transform(event.target.value))
        }
      },
      [onBlur, transform],
    )

    return (
      <x.div minW={minWidth || '100%'} width={minWidth || '100%'} {...xss}>
        {label && <Label htmlFor={name}>{label}</Label>}
        <S.TextAreaField
          ref={ref}
          id={name}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          fontSize={xss?.fontSize}
          whiteSpace={isResizable ? 'unset' : 'nowrap'}
          error={error}
          {...rest}
          {...textAreaFieldStyle}
        />
        {children}
        {error && typeof error === 'string' && <S.ErrorText>{error}</S.ErrorText>}
      </x.div>
    )
  },
)
