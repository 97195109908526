import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { MinusIcon, CheckIcon } from 'lucide-react'

import * as S from './Checkbox.styled'
import { Condition } from '../Condition'
import { Text } from '../Text'

type ElementProps = React.ElementRef<typeof CheckboxPrimitive.Root> & {
  xss?: SCProps<typeof S.$Label>
  error?: boolean | string
}

export type CheckboxProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
  xss?: SCProps<typeof S.$Label>
  error?: boolean | string
}

export const Checkbox = React.forwardRef<ElementProps, CheckboxProps>(({ children, xss, error, ...props }, ref) => {
  const isIndeterminate = props.checked === 'indeterminate'

  return (
    <S.$Label {...xss}>
      <S.$Checkbox ref={ref} {...props}>
        <S.$Indicator>
          <Condition when={!isIndeterminate} fallback={<MinusIcon color="white" width="100%" height="100%" />}>
            <CheckIcon color="white" width="100%" height="100%" />
          </Condition>
        </S.$Indicator>
      </S.$Checkbox>
      <Condition when={!!children}>
        <Text ml={children ? '10px' : '0px'} fontSize={xss?.fontSize ?? '14px'}>
          {children}
        </Text>
      </Condition>
      <Condition when={!error}>
        <Text ml={children ? '10px' : '0px'} fontSize={xss?.fontSize ?? '14px'} color="red.error">
          {error}
        </Text>
      </Condition>
    </S.$Label>
  )
})
