import { Form, Formik, FormikConfig, FormikHelpers, FormikProps, FormikValues } from 'formik'
import React from 'react'

import { isFunction } from '@/shared/utils'

import { FormikCheckbox } from './FormikCheckbox'
import { FormikErrorMessage } from './FormikErrorMessage'
import { FormikRadioGroup } from './FormikRadioGroup'
import { FormikSelect } from './FormikSelect'
import { FormikSubmitButton } from './FormikSubmitButton'
import { FormikTextField } from './FormikTextField'
import { FormikTextAreaField } from './FormikTextAreaField'
import { FormikTextFieldWithOptions } from './FormikTextFieldWithOptions'
import { FormikFormattedTextField } from './FormikFormattedTextField'

export type FormikFormProps<T = FormikValues> = RequireOnlyOne<FormikConfig<T>, 'initialValues' | 'validationSchema'> &
  Pick<BaseFormikFormProps<T>, 'children'> & { onChange?: (values: T, actions?: FormikHelpers<T>) => void }
export const FormikForm = <T extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit,
  onChange,
  ...rest
}: FormikFormProps<T>) => {
  const finalInitialValues = React.useMemo(() => {
    const defaultValues = validationSchema?.getDefaultFromShape()
    return {
      ...(defaultValues || {}),
      ...(initialValues || {}),
    }
  }, [initialValues, validationSchema])

  return (
    <Formik initialValues={finalInitialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formikProps: FormikProps<T>) => (
        <BaseFormikForm<T> {...formikProps} {...rest} onChange={(values) => onChange?.(values, formikProps)} />
      )}
    </Formik>
  )
}

FormikForm.TextField = FormikTextField
FormikForm.FormattedTextField = FormikFormattedTextField
FormikForm.TextAreaField = FormikTextAreaField
FormikForm.TextFieldWithOptions = FormikTextFieldWithOptions
FormikForm.Select = FormikSelect
FormikForm.RadioGroup = FormikRadioGroup
FormikForm.Checkbox = FormikCheckbox
FormikForm.SubmitButton = FormikSubmitButton
FormikForm.ErrorMessage = FormikErrorMessage

export type BaseFormikFormProps<T> = FormikProps<T> & {
  children: React.ReactNode | ((props: FormikProps<T>) => React.ReactNode)
  onChange?: (values: T) => void
}
export const BaseFormikForm = <T extends FormikValues>({
  children,
  onChange,
  ...formikProps
}: BaseFormikFormProps<T>) => {
  React.useEffect(() => {
    if (onChange) {
      onChange(formikProps.values)
    }
  }, [formikProps.values, onChange])

  return <Form>{isFunction(children) ? children(formikProps) : children}</Form>
}
