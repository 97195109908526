import React from 'react'
import styled, { th, x, system } from '@xstyled/styled-components'

import { Label } from '../Label'
import * as S from './TextField.styled'
import { Condition } from '../Condition'
import { Loader } from '../Loader'

const StyledFormikTextField = styled(S.TextField)`
  input {
    padding: 0 10px;
    border-radius: 5px;
    color: ${th.color('grey.48')};
    background-color: ${th.color('white')};
    border: 1px solid ${th.color('grey.24')};

    &:hover:not(:focus-within) {
      color: ${th.color('grey.64')};
      border: 1px solid ${th.color('grey.48')};
    }

    &:disabled {
      color: ${th.color('grey.48')};
      border: 1px solid ${th.color('grey.24')};
      background-color: ${th.color('grey.4')};
      cursor: not-allowed;
    }

    &:focus-visible,
    &:focus,
    &:focus-within {
      color: ${th.color('grey.100')};
      border: 1px solid ${th.color('grey.48')};
      background-color: ${th.color('white')};
      border: 1px solid ${th.color('purple.64')};
      outline: none;
      display: flex;
    }
  }

  ${system}
`

export type TextFieldProps = Omit<S.TextFieldProps, 'onChange' | 'onBlur' | 'error'> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> & {
    xss?: SCProps<typeof x.div>
    onChange?: (value: string) => void
    onBlur?: (value?: string) => void
    transform?: (value: string) => string
    label?: React.ReactNode
    error?: boolean | string
    isLoading?: boolean
  }

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ xss, children, name, label, onChange, transform = (v) => v, error, isLoading, ...rest }, ref) => {
    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(transform(event.target.value))
        }
      },
      [onChange, transform],
    )

    return (
      <x.div {...xss}>
        {label && <Label htmlFor={name}>{label}</Label>}
        <x.div display="flex" position="relative">
          <StyledFormikTextField
            ref={ref}
            id={name}
            name={name}
            onClick={(e: any) => e?.stopPropagation()}
            onChange={handleChange}
            error={!!error}
            {...rest}
          />
          <Condition when={!!isLoading}>
            <x.div position="absolute" right={10} top="50%" transform="translateY(-50%)">
              <Loader size={16} variant="purple" />
            </x.div>
          </Condition>
        </x.div>
        {children}
        {error && typeof error === 'string' && <S.ErrorText>{error}</S.ErrorText>}
      </x.div>
    )
  },
)
