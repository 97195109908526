import React from 'react'
import { useFormikContext } from 'formik'

import { Button } from '@/kit'

export type FormikSubmitButtonProps = React.ComponentProps<typeof Button> & {
  children?: React.ReactNode
}

export const FormikSubmitButton = ({ children, text, ...props }: FormikSubmitButtonProps) => {
  const { isSubmitting } = useFormikContext()
  const defaultText = isSubmitting ? 'Saving...' : 'Save'

  return (
    <Button
      type="submit"
      disabled={isSubmitting}
      loading={isSubmitting}
      {...props}
      text={children ? undefined : text || defaultText}
    >
      {children}
    </Button>
  )
}
