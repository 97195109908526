import React from 'react'

import { IconButton } from '@/kit/IconButton/IconButton.styled'
import { Icons } from '@/assets'

import * as S from './SelectedItem.styled'

export const SelectedItem = React.forwardRef<HTMLDivElement, any>(({ children, onRemove, ...rest }, ref) => {
  return (
    <S.SelectedItem ref={ref} {...rest} tabIndex={-1}>
      {children}
      <IconButton className="RemoveButton" title="Remove" onClick={onRemove}>
        <Icons.CloseIcon />
      </IconButton>
    </S.SelectedItem>
  )
})
