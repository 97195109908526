import styled, { th } from '@xstyled/styled-components'

export const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: ${th.size('xs')};
  color: ${th.color('purple.64')};
  white-space: nowrap;
  background-color: ${th.color('purple.4')};
  border-radius: 5px;

  :hover {
    cursor: pointer;
    color: ${th.color('purple.100')};
    background-color: ${th.color('purple.8')};
  }

  .RemoveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
    height: 100%;
    background-color: transparent;
    outline: none;

    :focus {
      border: 1px solid ${th.color('purple.64')};
      outline: none;
    }

    :hover,
    :focus {
      background-color: transparent;

      > svg {
        outline: none;
      }
      path {
        outline: none;
        fill: ${th.color('purple.100')};
      }
    }

    > svg {
      width: 14px;
      height: 14px;

      path {
        fill: ${th.color('purple.64')};
      }
    }
  }
`
